<template>
    <div class="project-classify">
        <div class="classify-content">
            <div class="project-classify-list">
                <div class="header-select">
                    <div class="item">
                        <span class="sort-item" :class="{current_first: currentFirstClass === 0}" @click="chooseFirstClass(0)">全部</span>
                        <span class="sort-item"
                              :class="{current_first: currentFirstClass === selectItem.rc_id}"
                              v-for="selectItem in projectFirstClassList"
                              :key="selectItem.rc_id"
                              @click="chooseFirstClass(selectItem.rc_id)">
                        {{selectItem.rc_name}}
                    </span>
                    </div>
                    <div class="item second-item">
                        <span class="sort-item" v-if="projectSecondClassList.length > 0" :class="{current_second: currentSecondClass === 0}" @click="chooseSecondClass(0)">全部</span>
                        <span class="sort-item"
                              :class="{current_second: currentSecondClass === selectItem.rc_id}"
                              v-for="selectItem in projectSecondClassList"
                              :key="selectItem.rc_id"
                              @click="chooseSecondClass(selectItem.rc_id)">
                        {{selectItem.rc_name}}
                    </span>
                        <span v-if="projectSecondClassList.length === 0" class="sort-item">暂无数据</span>
                    </div>
                </div>
                <div class="third-class-list">
                    <el-scrollbar :native="false">
                        <div class="third-class-item" v-for="item in projectThirdClassList" @click="viewProjectDetail(item)">
                            <div class="cover">
                                <img :src="item.file_path.src" alt="">
                            </div>
                            <div class="third-class-info">
                                <span class="class-name" :title="item.rc_name">{{item.rc_name}}</span>
                                <div class="class-time-difficulty">
                                    <span class="time">总时长：{{item.total_project_time}}分钟</span>
                                    <div class="difficulty" v-if="item.avg_project_difficulty === 1">
                                        <span class="text">难度</span>
                                        <i class="iconfont">&#xe64c;</i>
                                    </div>
                                    <div class="difficulty" v-if="item.avg_project_difficulty === 2">
                                        <span class="text">难度</span>
                                        <i class="iconfont" v-for="n in item.avg_project_difficulty">&#xe64c;</i>
                                    </div>
                                    <div class="difficulty" v-if="item.avg_project_difficulty === 3">
                                        <span class="text">难度</span>
                                        <i class="iconfont" v-for="n in item.avg_project_difficulty">&#xe64c;</i>
                                    </div>
                                    <div class="difficulty" v-if="item.avg_project_difficulty === 4">
                                        <span class="text">难度</span>
                                        <i class="iconfont" v-for="n in item.avg_project_difficulty">&#xe64c;</i>
                                    </div>
                                    <div class="difficulty" v-if="item.avg_project_difficulty === 5">
                                        <span class="text">难度</span>
                                        <i class="iconfont" v-for="n in item.avg_project_difficulty">&#xe64c;</i>
                                    </div>
                                </div>
                                <div class="class-view-like">
                                    <div class="view">
                                        <i class="iconfont">&#xe749;</i>
                                        {{item.sum_project_pv}}
                                    </div>
                                    <div class="like">
                                        <i class="iconfont">&#xeca1;</i>
                                        {{item.sum_project_live}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-scrollbar>
                    <div v-if="projectThirdClassList.length === 0" class="no-data">
                        <span>暂无数据</span>
                    </div>
                </div>
            </div>
            <div class="project-guess-list">
                <div class="guess-header">
                    <div class="guess-text" v-show="is_switch">
                        <img src="../../assets/images/project_01.png" class="header-img">
                        <span class="header-text">热门推荐</span>
                    </div>
                    <div class="guess-text" v-show="!is_switch">
                        <img src="../../assets/images/project_02.png" class="header-img">
                        <span class="header-text">猜你喜欢</span>
                    </div>
                    <div class="switch" @click="switchItem">
                        <i class="iconfont">&#xec8d;</i>
                        <span class="switch-text">切换</span>
                    </div>
                </div>
                <div class="guess-content">
                    <div class="guess-list">
                        <el-scrollbar :native="false" v-show="is_switch">
                            <div class="guess-item" v-for="item in hotProject" @click="goToProjectDetail(item)">
                                <img src="../../assets/images/project_03.png" v-if="item.rank === 1">
                                <img src="../../assets/images/project_04.png" v-else-if="item.rank === 2">
                                <img src="../../assets/images/project_05.png" v-else-if="item.rank === 3">
                                <span class="nums" v-else>{{item.rank}}</span>
                                <span class="guess-title">{{item.project_title}}</span>
                                <span class="num">{{item.project_pv}}</span>
                            </div>
                        </el-scrollbar>
                        <el-scrollbar :native="false" v-show="!is_switch">
                            <div class="guess-item" v-for="item in guessLikeProject" @click="goToProjectDetail(item)">
                                <img src="../../assets/images/project_03.png" v-if="item.rank === 1">
                                <img src="../../assets/images/project_04.png" v-else-if="item.rank === 2">
                                <img src="../../assets/images/project_05.png" v-else-if="item.rank === 3">
                                <span class="nums" v-else>{{item.rank}}</span>
                                <span class="guess-title">{{item.project_title}}</span>
                                <span class="num">{{item.project_livenum}}</span>
                            </div>
                        </el-scrollbar>
                    </div>
                </div>
            </div>
        </div>
        <el-pagination class="pages-left"
                       :current-page="projectClassPages.currentPageNum"
                       :page-size="projectClassPages.eachPageNum"
                       :total="projectClassPages.total"
                       layout="prev, pager, next"
                       @current-change="projectClassCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    export default {
        name: "Project",
        data() {
            return {
                //分类
                projectFirstClassList: [],
                projectSecondClassList: [],
                projectThirdClassList: [],
                currentFirstClass: null,
                currentSecondClass: null,
                //分页
                projectClassPages: {
                    currentPageNum: 1,
                    eachPageNum: 8,
                    total: 0,
                },
                //切换猜你喜欢/热门推荐
                is_switch: true,
                hotProject: [],
                guessLikeProject: []
            }
        },
        mounted() {
            this.getProjectFirstClassList();
        },
        methods: {
            //获取项目库一级分类
            getProjectFirstClassList() {
                this.$http.axiosGet(this.$api.admin_projectClassList, (res) => {
                    if (res.code === 200) {
                        this.projectFirstClassList = res.data.data;
                        if (res.data.data.length > 0) {
                            this.currentFirstClass = 0;
                            this.currentSecondClass = 0;
                            this.projectFirstClassList.forEach((item) => {
                                item.children.forEach((secondItem) => {
                                    this.projectSecondClassList.push(secondItem);
                                });
                            });

                            this.projectSecondClassList.forEach((item) => {
                                item.children.forEach((thirdItem) => {
                                    this.projectThirdClassList.push(thirdItem);
                                });
                            });
                        }
                        this.hotProject = res.data.hotProject;
                        this.guessLikeProject = res.data.guessLikeProject;
                    } else {
                        this.$message.error(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //获取项目库二级分类
            getProjectSecondClassList(id) {
                this.projectFirstClassList.forEach((item) => {
                    if (item.rc_id === id) {
                        this.projectSecondClassList = item.children;
                    }
                })
            },
            //获取项目库三级分类
            getProjectThirdClassList(id) {
                if (this.currentSecondClass > 0) {
                    this.projectFirstClassList.forEach((item) => {
                        item.children.forEach((thirdItem) => {
                            if (thirdItem.rc_id === id) {
                                this.projectThirdClassList = thirdItem.children;
                            }
                        })
                    })
                } else {
                    this.projectSecondClassList.forEach((item) => {
                        item.children.forEach((thirdItem) => {
                            this.projectThirdClassList.push(thirdItem);
                        });
                    });
                }
            },
            //选择一级分类
            chooseFirstClass(id) {
                this.currentFirstClass = id;
                this.projectSecondClassList = [];
                this.projectThirdClassList = [];
                if (id > 0) {
                    this.currentSecondClass = 0;
                    this.getProjectSecondClassList(id);
                    this.getProjectThirdClassList(id);
                } else {
                    this.currentSecondClass = 0;
                    this.projectFirstClassList.forEach((item) => {
                        item.children.forEach((secondItem) => {
                            this.projectSecondClassList.push(secondItem);
                        });
                    });
                    this.projectSecondClassList.forEach((item) => {
                        item.children.forEach((thirdItem) => {
                            this.projectThirdClassList.push(thirdItem);
                        });
                    });
                }
            },
            //选择二级分类
            chooseSecondClass(id) {
                this.currentSecondClass = id;
                this.projectThirdClassList = [];
                if (id > 0) {
                    this.getProjectThirdClassList(id);
                } else {
                    this.projectSecondClassList.forEach((item) => {
                        item.children.forEach((thirdItem) => {
                            this.projectThirdClassList.push(thirdItem);
                        });
                    });
                }
            },
            //点击三级分类
            viewProjectDetail(item) {
                this.$router.push({
                    path: '/admin/projectLibrary/project',
                    query: {
                        id: item.rc_id
                    }
                })
            },
            //查看项目详情
            goToProjectDetail(item) {
                this.$router.push({
                    path: '/admin/index/projectDetail',
                    query: {
                        id: item.id
                    },
                })
            },
            //分页
            projectClassCurrentChange(val) {
                this.projectClassPages.currentPageNum = val;
                // this.getSchoolList();
            },
            //切换猜你喜欢/热门推荐
            switchItem() {
                this.is_switch = !this.is_switch;
            }
        }
    }
</script>

<style scoped lang="scss">
    .project-classify {
        display: flex;
        flex-direction: column;
        /*min-width: 1449px;*/
        height: 100%;
        background-color: #fff;
        overflow: auto;
        .classify-content {
            display: flex;
            flex: 1;
            justify-content: space-between;
            height: 1%;
        }
        .project-classify-list {
            display: flex;
            flex: 1;
            flex-direction: column;
            width: 1%;
            min-width: 1136px;
            height: 100%;
            .header-select {
                width: 100%;
                height: auto;
                background-color: #fff;
                .item {
                    display: flex;
                    flex-wrap: wrap;
                    color: #666666;
                    font-size: 16px;
                    padding: 20px 70px;
                    .sort-item {
                        min-width: 70px;
                        height: 32px;
                        line-height: 32px;
                        text-align: center;
                        margin: 0 10px 5px 10px;
                        padding: 0 10px;
                        &:hover {
                            cursor: pointer;
                            color: #3889FF;
                        }
                    }
                    .current_first {
                        background: linear-gradient(0deg, #0097EB 0%, #75D7F4 100%);
                        border-radius: 16px;
                        color: #fff;
                    }
                }
                .second-item {
                    font-size: 14px;
                    .current_second {
                        background: linear-gradient(0deg, #0097EB 0%, #75D7F4 100%);
                        border-radius: 16px;
                        color: #fff;
                        font-size: 14px;
                    }
                }
            }
            .third-class-list {
                display: flex;
                flex: 1;
                width: 100%;
                height: calc(100vh - 298px);
                /*min-width: 1020px;*/
                padding: 10px 20px 10px 70px;
                box-sizing: border-box;
                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                    .el-scrollbar__view {
                        display: flex;
                        flex-wrap: wrap;
                    }
                }
                .third-class-item {
                    display: flex;
                    width: calc((100% - 32px)/2);
                    min-width: 500px;
                    height: 190px;
                    margin: 8px 8px 16px 8px;
                    &:hover {
                        cursor: pointer;
                        box-shadow: 0 0 8px 0 rgba(16, 160, 236, 0.2);
                        border-radius: 0 4px 4px 0;
                    }
                    &:nth-child(2n+1) {
                        margin: 8px 8px 16px 8px;
                    }
                    .cover {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width: 258px;
                        height: 100%;
                        img {
                            /*max-width: 100%;*/
                            /*max-height: 100%;*/
                        }
                    }
                    .third-class-info {
                        display: flex;
                        flex: 1;
                        flex-direction: column;
                        justify-content: space-around;
                        width: 1%;
                        min-width: 290px;
                        padding: 27px;
                        .class-name {
                            font-size: 22px;
                            display: block;
                            width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        .class-time-difficulty {
                            display: flex;
                            color: #999999;
                            font-size: 14px;
                            .time {
                                margin-right: 20px;
                            }
                            .difficulty {
                                i {
                                    color: #FFD83E;
                                }
                            }
                        }
                        .class-view-like {
                            display: flex;
                            color: #7D7D7D;
                            .view, .like {
                                display: flex;
                                align-items: center;
                                i {
                                    margin-right: 5px;
                                }
                            }
                            .view {
                                margin-right: 30px;
                            }
                        }
                    }
                }
            }
        }
        .project-guess-list {
            display: flex;
            flex-direction: column;
            width: 327px;
            padding: 18px 20px 0;
            box-sizing: border-box;
            .guess-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 30px;
                margin-bottom: 15px;
                .guess-text {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    img.header-img {
                        width: 23px;
                        height: 19px;
                        margin-right: 16px;
                    }
                    span.header-text {
                        font-size: 18px;
                    }
                }
                .switch {
                    display: flex;
                    align-items: center;
                    color: #3889ff;
                    cursor: pointer;
                    i {
                        margin-right: 10px;
                    }
                }
            }
            .guess-content {
                flex: 1;
                height: 1%;
                .guess-list {
                    height: 100%;
                    ::v-deep .el-scrollbar {
                        height: 100%;
                    }
                    ::v-deep .el-scrollbar__wrap {
                        overflow-x: hidden;
                    }
                    .guess-item {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 40px;
                        img {
                            width: 29px;
                            height: 23px;
                        }
                        .guess-title {
                            display: flex;
                            flex: 1;
                            width: 1%;
                            color: #666666;
                            font-size: 16px;
                            margin: 0 30px 0 10px;
                            overflow: hidden;
                            text-overflow:ellipsis;
                            white-space: nowrap;
                            cursor: pointer;
                        }
                        .num {
                            color: #666666;
                            font-size: 14px;
                        }
                        .rank {
                            display: block;
                            width: 29px;
                            font-size: 16px;
                            color: #999;
                            font-weight: bold;
                            text-align: center;
                        }
                    }
                }
            }
        }
        .pages-left {
            text-align: center;
        }
    }
    .no-data {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    .nums {
        display: block;
        width: 23px;
        text-align: center;
    }
</style>